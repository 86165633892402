.experience {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  width: 100%;
}

@media (max-width: 600px) {
  .experience {
    width: 100%;
  }
}

.experience-name {
  font-weight: bold;
  font-size: 1.3em;
}

.experience-loc, .experience-bullet > a {
  color: var(--electric-green);
  text-decoration: none;
}

.experience-loc:hover, .experience-bullet > a:hover {
  text-decoration: underline;
}

.experience-date {
  font-size: .9em;
  color: var(--green-blue);
  padding-bottom: 1%;
}

.experience-bullet {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-bottom: 1%;
}

.experience-arrow {
  padding-right: 3%;
  color: var(--electric-green);
}

.experience-bullet > a {
  line-height: 1em;
}

.experience-list {
  width: 100%;
}



