.section {
  min-height: 100%;
  min-height: -webkit-fill-available;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  color: var(--light);
  background: var(--gunmetal);
}

.section-title {
  font-size: 4em;
  font-weight: bold;
  text-align: center;
  height: 10%;
  margin-top: 1%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.section-body {
  width: 55%;
  padding-bottom: 8%;
  padding-top: 2%;
  margin: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section-title > div:hover {
  color: var(--electric-green);
}

.section-title > div {
  transition: .25s ease;
}

@media (max-width: 678px) {
  .section-title > div:hover {
    color: var(--light);
  }
}