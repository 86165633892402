@import url('https://fonts.googleapis.com/css?family=Share+Tech&display=swap');

@font-face {
  font-family: 'POLYA Regular';
  src: url('./fonts/POLYA.eot?#iefix') format('embedded-opentype'),
  url('./fonts/POLYA.woff') format('woff'),
  url('./fonts/POLYA.otf') format('otf'),
  url('./fonts/POLYA.svg#svgFontName') format('svg');
}

#root {
  font-family: 'Share Tech', sans-serif;
  --gunmetal: #122932;
  --green-blue: #476A6F;
  --light-green: #519E8A;
  --light-blue: #8DA7BE;
  --alabaster: #F2F0E6;
  --light: #D7E1E5;
  --electric-green: #64FFDA;
  height: 100%;
  width: 100%;
}

#body {
  position: fixed;
  left: 0;
  top: 0;
  margin-top: 5vh;
  width: 100%;
  height: 95%;
  overflow-x: hidden;
  overflow-y: visible;
  scroll-behavior: smooth;
}